<fieldset [ngClass]="{'disabled': disabled === true, 'readonly': readOnly === true}">
	<label [for]="id">
		<ng-content select="[slot=label]"></ng-content>
		<ng-container *ngIf="isRequired()">*</ng-container>
	</label>
	<div id="wrapper">
		<select
			[attr.name]="name" (blur)="onFocus()" [formControl]="_formControl" [hidden]="hidden" [id]="id"
		>
			<option
				*ngIf="placeholder.length > 0" class="placeholder" disabled value=""
			>{{placeholder}}</option>
			<option *ngFor="let option of options" [disabled]="option.disabled" [value]="option.value">
				<ng-container [ngTemplateOutlet]="option.label"></ng-container>
			</option>
		</select>
		<div class="select-icon">
			<mat-icon>keyboard_arrow_down</mat-icon>
		</div>
	</div>
	<div *ngIf="getHintResults() != null" id="hints">
		<p *ngFor="let item of getHintResults() | keyvalue" [ngClass]="item.value.level" class="hint">
			{{item.value.message}}
		</p>
	</div>
</fieldset>
